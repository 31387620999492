import { differenceInMonths, differenceInYears } from 'date-fns'
import { TFunction } from 'i18next'
import {
  monthFormatDate,
  monthYearFormatDate,
  monthYearTemplateFormatDate,
  yearFormatDate
} from '~/core/utilities/format-date'
import { CertificatesType, EducationsType } from '../types'

export const checkCertificateFieldFormatDate = ({
  attributes
}: {
  type?: string
  attributes?: CertificatesType & {
    from?: {
      month?: string
      year?: string
    }
  }
}) => {
  if (!attributes || Object.keys(attributes).length === 0) return false

  return attributes?.issueMonth || attributes?.issueYear ? true : false
}

export const getCertificateFieldFormatDate = ({
  attributes = {} as any,
  isTemplate = false
}) => {
  if (Object.keys(attributes).length === 0) return false
  const formatDate = `${attributes.issueYear}-${
    attributes.issueMonth >= 10
      ? attributes.issueMonth
      : `0${attributes.issueMonth}`
  }`
  if (attributes?.issueMonth) {
    if (attributes?.issueYear) {
      return isTemplate
        ? `${monthYearTemplateFormatDate(new Date(formatDate))} `
        : `${monthYearFormatDate(new Date(formatDate))} `
    }

    return attributes.issueMonth
  } else {
    return attributes.issueYear
  }
}

export const getEducationTime = ({
  startDate,
  endDate,
  t
}: {
  startDate?: string
  endDate?: string
  t: TFunction
}) => {
  let months = 0
  let year = 0
  if (!startDate || !endDate) return ''
  if (startDate === endDate)
    return `${t('candidates:tabs:candidateOverview:education:moreThanMonth')}`

  const endDateDayjs = new Date(endDate)
  const startDateDayjs = new Date(startDate)
  months = differenceInMonths(endDateDayjs, startDateDayjs)
  year = differenceInYears(endDateDayjs, startDateDayjs)

  if (year >= 1) {
    year = Math.floor(year)
    months -= 12 * year
  }

  if (months > 0 && year < 1) {
    return `${months} ${
      months > 1
        ? `${t('candidates:tabs:candidateOverview:education:months')}`
        : `${t('candidates:tabs:candidateOverview:education:month')}`
    }`
  }

  if (months >= 0 && year == 1) {
    return `${year} ${t('candidates:tabs:candidateOverview:education:year')} ${
      months
        ? `${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }

  if (year > 1) {
    return `${year} ${
      year > 1
        ? `${t('candidates:tabs:candidateOverview:education:years')}`
        : `${t('candidates:tabs:candidateOverview:education:year')}`
    }${
      months > 0
        ? ` ${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }

  return ''
}

export const checkEducationFieldFormatDate = ({
  type = 'from',
  attributes
}: {
  type?: string
  attributes?: EducationsType & {
    from?: {
      month?: string
      year?: string
    }
    to?: {
      month?: string
      year?: string
    }
  }
}) => {
  if (!attributes || Object.keys(attributes).length === 0) return false

  if (type === 'from') {
    return attributes?.from || attributes?.from?.month || attributes?.from?.year
      ? true
      : false
  }

  return attributes?.to || attributes?.to?.month || attributes?.to?.year
    ? true
    : false
}

export const getEducationFieldFormatDate = ({
  type = 'from',
  attributes,
  formatDate = 'MMM yyyy',
  isTemplate = false
}: {
  type?: string
  formatDate?: string
  attributes?: EducationsType & {
    from?: {
      month?: string
      year?: string
    }
    to?: {
      month?: string
      year?: string
    }
  }
  isTemplate?: boolean
}) => {
  if (!attributes || Object.keys(attributes).length === 0) return false

  if (type === 'from') {
    if (attributes?.from?.month && attributes?.from?.year) {
      return isTemplate
        ? `${monthYearTemplateFormatDate(
            new Date(`${attributes?.from?.year}-${attributes?.from?.month}`)
          )} `
        : `${attributes?.from?.month}/${attributes?.from?.year}`
    }

    if (attributes?.from?.month && !attributes?.from?.year) {
      return `${monthFormatDate(new Date(attributes.from?.month))} `
    }

    if (!attributes?.from?.month && attributes?.from?.year) {
      return `${yearFormatDate(new Date(attributes.from?.year))} `
    }

    if (typeof attributes?.from === 'string') {
      return isTemplate
        ? `${monthYearTemplateFormatDate(new Date(attributes.from))} `
        : `${monthYearFormatDate(new Date(attributes.from))} `
    }

    return ''
  }

  if (type === 'to') {
    if (attributes?.to?.month && attributes?.to?.year) {
      return isTemplate
        ? `${monthYearTemplateFormatDate(
            new Date(`${attributes?.to?.year}-${attributes?.to?.month}`)
          )} `
        : `${attributes?.to?.month}/${attributes?.to?.year}`
    }

    if (attributes?.to?.month && !attributes?.to?.year) {
      return `${monthFormatDate(new Date(attributes.to?.month))} `
    }

    if (!attributes?.to?.month && attributes?.to?.year) {
      return `${yearFormatDate(new Date(attributes.to?.year))} `
    }

    if (typeof attributes?.to === 'string') {
      return isTemplate
        ? `${monthYearTemplateFormatDate(new Date(attributes.to))} `
        : `${monthYearFormatDate(new Date(attributes.to))} `
    }

    return ''
  }

  return ''
}

export const getWorkExperienceTime = (
  item: {
    fromMonth?: number
    fromYear?: number
    toMonth?: number
    toYear?: number
    currentWorking?: boolean
  },
  t: TFunction
) => {
  let months = 0
  let year = 0
  const getFromMonth = item.fromMonth || 1
  const getToMonth = item.toMonth || 1
  const startDate = `${item.fromYear}-${
    getFromMonth >= 10 ? getFromMonth : `0${getFromMonth}`
  }`
  const endDate = `${item.toYear}-${
    getToMonth >= 10 ? getToMonth : `0${getToMonth}`
  }`
  const endDateDayjs = item.currentWorking ? new Date() : new Date(endDate)
  const startDateDayjs = new Date(startDate)
  months = differenceInMonths(endDateDayjs, startDateDayjs)
  year = differenceInYears(endDateDayjs, startDateDayjs)

  if (year >= 1) {
    year = Math.floor(year)
    months -= 12 * year
  }

  if (months > 0 && year < 1) {
    return `${t('candidates:tabs:candidateOverview:education:lessThanYear')}`
  }

  if (months >= 0 && year == 1) {
    return `${year} ${t('candidates:tabs:candidateOverview:education:year')}${
      months && item.fromMonth && item.toMonth
        ? ` ${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }

  if (year > 1) {
    return `${year} ${
      year > 1
        ? `${t('candidates:tabs:candidateOverview:education:years')}`
        : `${t('candidates:tabs:candidateOverview:education:year')}`
    }${
      months > 0 && item.fromMonth && item.toMonth
        ? ` ${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }
  return ''
}

export const getWorkExpFieldFormatDate = ({
  type = 'from',
  attributes = {} as any,
  isTemplate = false
}) => {
  if (Object.keys(attributes).length === 0) return false
  if (type === 'from') {
    const formatDate = `${attributes.fromYear}-${
      attributes.fromMonth >= 10
        ? attributes.fromMonth
        : `0${attributes.fromMonth}`
    }`
    if (attributes?.fromMonth) {
      if (attributes.fromYear) {
        return isTemplate
          ? `${monthYearTemplateFormatDate(new Date(formatDate))} `
          : `${monthYearFormatDate(new Date(formatDate))} `
      }

      return attributes.fromMonth
    } else {
      return attributes.fromYear
    }
  }
  if (type === 'to') {
    const formatDate = `${attributes.toYear}-${
      attributes.toMonth >= 10 ? attributes.toMonth : `0${attributes.toMonth}`
    }`
    if (attributes?.toMonth) {
      if (attributes.toMonth) {
        return isTemplate
          ? `${monthYearTemplateFormatDate(new Date(formatDate))} `
          : `${monthYearFormatDate(new Date(formatDate))} `
      }

      return attributes?.toMonth
    } else {
      return attributes.toYear
    }
  }
  return ''
}
