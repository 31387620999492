'use client'

import * as PopoverPrimitive from '@radix-ui/react-popover'
import * as React from 'react'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverPortal = PopoverPrimitive.Portal

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(
  (
    {
      className = 'p-2',
      align = 'center',
      side = 'bottom',
      sideOffset = 4,
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      side={side}
      sideOffset={sideOffset}
      className={`z-50 rounded bg-white shadow-ats outline-none dark:bg-gray-900 dark:shadow-dark-ats ${
        className || 'w-72'
      }`}
      {...props}
    />
  )
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverTrigger, PopoverPortal, PopoverContent }
